import { Container } from '@mui/material';
import Provision from '../../components/pages/PensionProvision/Provision';
import Page from '#/components/shared/ui/Page';

export default function PensionProvision() {
  return (
    <Page title="Vorsorge">
      <Container component="main" sx={{ marginY: 12 }}>
        <Provision />
      </Container>
    </Page>
  );
}
