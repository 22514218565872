import * as Yup from 'yup';

export const stepOneSchema = () =>
  Yup.object().shape({
    // question1: Yup.string().required('A choice is required'),
    // question2: Yup.string().required('A choice is required'),
    // question3: Yup.string().required('A choice is required'),
  });

export const stepTwoSchema = () =>
  Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),

    mobile_phone: Yup.string().when('first_name', {
      is: (val: string) => val.length > 0,
      then: (schema) =>
        schema
          .required('validations.user.mobile_phone.required')
          .max(22, 'validations.user.mobile_phone.max')
          .matches(/^[0-9\s+]+$/, 'validations.user.mobile_phone.matches')
          .min(7, 'validations.user.mobile_phone.min'),
    }),

    email: Yup.string()
      .email('Invalid email')
      .required('validations.user.email'),
    gender: Yup.string().required('validations.user.gender'),
  });
