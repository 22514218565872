import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Link as Anchor,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { provisionSteps } from './provisionSteps';
import { FormProvider } from '#/components/shared/hook-form';
import useAuth from '#/hooks/useAuth';
import {
  stepOneSchema,
  stepTwoSchema,
} from '#/components/pages/PensionProvision/schemaValidation';
import useLocales from '#/hooks/useLocales';

const Provision = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [enableRegistration, setEnableRegistration] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const fromPartner = localStorage.getItem('gg_partner_key');

  const { register } = useAuth();
  const navigate = useNavigate();
  const { translate, currentLang } = useLocales();

  const formSchemas = [stepOneSchema(), stepTwoSchema()];

  const defaultValues = useMemo(
    () => ({
      question1: '',
      question2: '',
      question3: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile_phone: '',
      reminder_frequency: 'disabled',
      gender: '',
      language: currentLang.value,
    }),
    [currentLang]
  );

  const methods = useForm<any>({
    resolver: formSchemas[activeStep]
      ? yupResolver(formSchemas[activeStep] as any)
      : undefined,
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const onSubmit = async (data: any) => {
    const { hostname } = window.location;
    const domainParts = hostname.split('.');
    const subdomain =
      domainParts.length > 2 ? domainParts.slice(0, -2).join('.') : '';

    const subscription_package = searchParams.get('subscription_package');
    const body = {
      ...data,
      email: data.email.toLowerCase(),
      ...(fromPartner && { partner: fromPartner }),
      ...(subdomain && !subdomain.includes('localhost') && { subdomain }),
      ...(subscription_package && {
        subscription_package,
      }),
    };

    if (activeStep === provisionSteps.length - 1) {
      setIsLoading(true);
      await register(body)
        .then(() => {
          setIsLoading(false);
          navigate(`/dashboard/vorsorge-final`, {
            state: {
              redirectLink: searchParams.get('redirectLink'),
            },
          });
        })
        .catch((err) => {
          setIsLoading(false);
          return err.errors.map((error: string) => toast.error(error));
        });
    } else {
      handleNext();
    }
  };

  // useEffect(() => {
  //   const initialVisitStatus = Cookies.get('initialVisitStatus');
  //   if (!initialVisitStatus) {
  //     Cookies.set('initialVisitStatus', 'true', { expires: 365 });
  //   }
  // }, []);

  useEffect(() => {
    if (currentLang.value) {
      setValue('language', currentLang.value);
    }
    // eslint-disable-next-line
  }, [currentLang.value]);

  useEffect(() => {
    if (state?.redirectLink) {
      setActiveStep(1);
      setSearchParams({
        redirectLink: state?.redirectLink,
      });
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        textAlign="center"
        sx={{
          marginTop: 12,
        }}
      >
        {String(translate('pensionProvision.title'))}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        textAlign="center"
        color="primary"
        sx={{ marginBottom: 12 }}
      >
        {String(translate('pensionProvision.alreadyHaveAcc'))} &nbsp;
        <Link
          to="/auth/login"
          style={{
            color: '#3366FF',
          }}
        >
          {String(translate('pensionProvision.signIn'))}
        </Link>
      </Typography>
      {provisionSteps
        .filter((step) => step.step === activeStep)
        .map((step) => (
          <FormProvider
            key={`step-${step.step}`}
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Card sx={{ p: 3 }}>
              {step.content({ handleNext })}
              {/* <Divider sx={{ marginY: 3 }} /> */}
              <Stack
                spacing={2}
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                alignItems="center"
                justifyContent={step.step === 1 ? 'space-between' : 'flex-end'}
                sx={{ marginY: 3 }}
              >
                {step.step === 1 && (
                  <Stack direction="column" justifyContent="flex-start">
                    <FormControlLabel
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '0.875rem',
                        },
                      }}
                      control={
                        <Checkbox
                          checked={enableRegistration}
                          onChange={(e) =>
                            setEnableRegistration(e.target.checked)
                          }
                        />
                      }
                      label={String(
                        translate('pensionProvision.confirmCheckbox')
                      )}
                    />
                    <Anchor
                      sx={{
                        textDecoration: 'none',
                      }}
                      href="https://storage.gutgeregelt.ch/docs/gut_geregelt_DPA.pdf"
                      target="_blank"
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        color="primary"
                      >
                        {String(
                          translate('pensionProvision.processingAgreement')
                        )}
                      </Typography>
                    </Anchor>
                  </Stack>
                )}
                <Stack direction="row">
                  {step.step > 0 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleBack}
                    >
                      <Typography>
                        {String(translate('global.back'))}
                      </Typography>
                    </Button>
                  )}
                  {step.step === 1 && (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ ml: 1 }}
                      disabled={!enableRegistration && step.step === 1}
                      loading={
                        isLoading && step.step === provisionSteps.length - 1
                      }
                    >
                      <Typography>
                        {String(translate('global.next'))}
                      </Typography>
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </Card>
          </FormProvider>
        ))}
    </Box>
  );
};

export default Provision;
