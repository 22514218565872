/* eslint-disable import/no-cycle */
import ProvisionStep1 from './ProvisionStep1';
import ProvisionStep2 from './ProvisionStep2';

export type PayloadData = {
  question1: string;
  question2: string;
  question3: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export const provisionSteps = [
  {
    step: 0,
    content: ({ handleNext }: { handleNext?: () => void }) => (
      <ProvisionStep1 handleNext={handleNext} />
    ),
  },
  {
    step: 1,
    content: () => <ProvisionStep2 />,
  },
];

export const step1Text = [
  {
    text: 'p1',
  },
  {
    text: 'p2',
  },
  {
    // color: 'primary',
    text: 'p3',
  },
  {
    color: 'primary',
    text: 'p3a',
  },
];

export const step1Cards = [
  {
    label: 'Living will',
    text: 'allows you to determine how medical professionals should proceed in the event of an emergency.',
    icon: '/assets/icons/ppLivingWill.svg',
    link: '/dashboard/patientenverfuegung',
  },
  {
    label: 'Power of attorney',
    text: 'avoids problems with the KESB and regulates guardianship for your children.',
    icon: '/assets/icons/ppPOA.svg',
    link: '/dashboard/vorsorgeauftrag',
  },
  {
    label: 'Last will',
    text: 'to leave your legacy the way you want it to be left.',
    icon: '/assets/icons/ppLastWill.svg',
    link: '/dashboard/testament',
  },
];

// export const step1Questions = [
//   {
//     id: '1',
//     value: 'question1',
//     question:
//       ' When you have the choice of doing something today or putting it off until tomorrow... What do vou do?',
//     answers: [
//       {
//         value: 'now',
//         label: 'I do it right away',
//       },
//       {
//         value: 'dontKnow1',
//         label: "I don't know",
//       },
//       {
//         value: 'preferTomorrow',
//         label: 'I prefer tomorrow',
//       },
//     ],
//   },
//   {
//     id: '2',
//     value: 'question2',
//     question:
//       ' When someone tries to motivate you to do something you don`t like... What works best?',
//     answers: [
//       {
//         value: 'incentivesMe',
//         label: 'Incentives me',
//       },
//       {
//         value: 'dontKnow2',
//         label: "I don't know",
//       },
//       {
//         value: 'makeMeFeelBad',
//         label: 'Make me feel bad',
//       },
//     ],
//   },
//   {
//     id: '3',
//     value: 'question3',
//     question: 'How do you usually tackle things ?',
//     answers: [
//       {
//         value: 'eyesShutAndGo',
//         label: 'Eyes shut and go for it',
//       },
//       {
//         value: 'dontKnow3',
//         label: "I don't know",
//       },
//
//       {
//         value: 'ratherLater',
//         label: 'Rather later',
//       },
//     ],
//   },
// ];

export const step2Text = [
  {
    text: 'p4',
  },
  {
    text: 'p4a',
  },
  {
    label: 'p5a',
    text: 'p5b',
  },
  {
    label: 'p6a',
    text: 'p6b',
  },
];
