/* eslint-disable import/no-cycle */
import { MenuItem, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import { PayloadData, step2Text } from './provisionSteps';
import { RHFSelect, RHFTextField } from '../../shared/hook-form';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from './styles';
import useLocales from '#/hooks/useLocales';
import 'react-international-phone/style.css';
import PhoneInput from '../../shared/hook-form/PhoneInput';

const genders = [
  {
    value: 'M',
    label: 'male',
  },
  {
    value: 'F',
    label: 'female',
  },
];

const civilStatus = [
  {
    code: 'single',
  },
  {
    code: 'married',
  },
  {
    code: 'divorced',
  },
  {
    code: 'widowed',
  },
  {
    code: 'registered_partnership',
  },
];

const ProvisionStep2 = () => {
  const { translate } = useLocales();
  const { setValue, watch } = useFormContext();

  const [provisionChoices, setProvisionChoices] = useState<
    string[] | undefined
  >();

  useEffect(() => {
    const choice1 = watch?.('question1' as keyof PayloadData);
    const choice2 = watch?.('question2' as keyof PayloadData);
    const choice3 = watch?.('question3' as keyof PayloadData);

    if (choice1 && choice2 && choice3) {
      setProvisionChoices([choice1, choice2, choice3]);
    }
  }, [watch]);

  const handlePhoneChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue('mobile_phone', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Stack spacing={2}>
      {step2Text
        .filter((_, index) =>
          provisionChoices?.includes('dontKnow1') ||
          provisionChoices?.includes('dontKnow2') ||
          provisionChoices?.includes('dontKnow3') ||
          provisionChoices?.includes('ratherLater') ||
          provisionChoices?.includes('makeMeFeelBad') ||
          provisionChoices?.includes('preferTomorrow')
            ? index % 2 === 0
            : index % 2 !== 0
        )
        .map((text) => (
          <Stack
            key={`step-${text.text}`}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="body1">
              {text.label && (
                <Typography variant="body1" component="span" fontWeight="bold">
                  {`${String(translate(`pensionProvision.${text.label}`))} `}
                </Typography>
              )}
              {String(translate(`pensionProvision.${text.text}`))}
            </Typography>
          </Stack>
        ))}
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
      >
        <RHFTextField
          name="first_name"
          label={String(translate('global.formLabels.firstName'))}
        />
        <RHFTextField
          name="last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
      >
        <RHFSelect
          variant="outlined"
          name="civil_status"
          label={String(translate('global.formLabels.civilStatus'))}
        >
          {civilStatus.map((status) => (
            <MenuItem key={status.code} value={status.code}>
              {String(translate(`global.civilStatus.${status.code}`))}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFSelect name="gender" label={String(translate('global.gender'))}>
          {genders.map((g, i) => (
            <MenuItem key={i} value={g.value}>
              {String(translate(`global.${g.label}`))}
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>

      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
      >
        <RHFTextField
          name="email"
          label={String(translate('global.formLabels.emailAddress'))}
        />
        <PhoneInput
          name="mobile_phone"
          value={watch('mobile_phone')}
          onChange={(e) => handlePhoneChange(e)}
          sx={{ width: '100%' }}
        />
      </Stack>
      <StyledRadioGroup>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {String(translate('pensionProvision.questions.3.question'))}
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
        >
          <StyledFormControlLabel
            value="weekly"
            control={
              <StyledRadio
                onChange={(event) => {
                  if (event.target.checked) {
                    setValue('reminder_frequency', event.target.value);
                  }
                }}
              />
            }
            label={String(
              translate('pensionProvision.questions.3.options.0.label')
            )}
          />
          <StyledFormControlLabel
            value="monthly"
            control={
              <StyledRadio
                onChange={(event) => {
                  if (event.target.checked) {
                    setValue('reminder_frequency', event.target.value);
                  }
                }}
              />
            }
            label={String(
              translate('pensionProvision.questions.3.options.1.label')
            )}
          />
          <StyledFormControlLabel
            value="quarterly"
            control={
              <StyledRadio
                onChange={(event) => {
                  if (event.target.checked) {
                    setValue('reminder_frequency', event.target.value);
                  }
                }}
              />
            }
            label={String(
              translate('pensionProvision.questions.3.options.2.label')
            )}
          />
        </Stack>
      </StyledRadioGroup>
    </Stack>
  );
};

export default ProvisionStep2;
