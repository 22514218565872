import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import SvgIconStyle from '#/components/shared/ui/SvgIconStyle';
import useLocales from '#/hooks/useLocales';

type Props = {
  card: {
    icon: string;
    label: string;
    text: string;
    link: string;
  };
  cardNo: number;
  getStarted?: () => void;
};
const StepOneCards = ({ card, cardNo, getStarted }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const { icon, label } = card;
  const { translate } = useLocales();

  const handleGetStarted = (redirectLink: string) => {
    const subscription_package = state?.subscription_package;
    setSearchParams({
      redirectLink,
      ...(subscription_package && { subscription_package }),
    });
    if (getStarted) {
      getStarted();
    }
  };

  return (
    <Stack
      key={`step-${label}`}
      direction="column"
      alignItems="center"
      spacing={3}
      sx={{
        border: '1px solid #E5E5E5',
        p: 2.5,
        borderRadius: '10px',
        marginY: 2,
        position: 'relative',
      }}
    >
      <SvgIconStyle
        src={icon}
        sx={{
          width: '50px !important',
          height: '50px !important',
          backgroundColor: 'primary.main',
        }}
      />
      <Typography variant="h6" color="text.primary">
        {String(translate(`pensionProvision.c${cardNo}.title`))}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          textAlign: 'center',
          maxWidth: '200px',
          paddingBottom: '35px',
        }}
      >
        {String(translate(`pensionProvision.c${cardNo}.description`))}
      </Typography>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: '0',
          left: '0',
        }}
        onClick={() => handleGetStarted(card.link)}
      >
        {String(translate(`global.dashCardInfos.getStarted`))}
      </Button>
    </Stack>
  );
};

export default StepOneCards;
