/* eslint-disable import/no-cycle */
import { Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { step1Cards, step1Text } from './provisionSteps';
// import {
//   StyledFormControlLabel,
//   StyledRadio,
//   StyledRadioGroup,
// } from './styles';
import StepOneCards from '#/components/pages/PensionProvision/StepOneCards';
import useLocales from '#/hooks/useLocales';

type StepOneProps = {
  handleNext?: () => void;
};

const ProvisionStep1 = ({ handleNext }: StepOneProps) => {
  // const { control, setValue, watch } = useFormContext();

  const { translate } = useLocales();

  // const handleOnChange = (
  //   questionName: string,
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setValue?.(questionName as keyof PayloadData, event.target.value);
  // };

  // const handleDefaultValues = (question: string) =>
  //   watch?.(`${question}` as keyof PayloadData);

  // const translatedQuestions = step1Questions.map((question, i) => ({
  //   ...question,
  //   question: translate(`pensionProvision.questions.${i}.question`),
  //   answers: question.answers.map((answer, j) => ({
  //     ...answer,
  //     label: translate(`pensionProvision.questions.${i}.options.${j}.label`),
  //   })),
  // }));

  return (
    <Stack spacing={1.5}>
      {step1Text
        .filter((_, index) => index <= 2)
        .map((text) => (
          <Stack
            key={`step-${text.text}`}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="body1" color={text.color}>
              {String(translate(`pensionProvision.${text.text}`))}
            </Typography>
          </Stack>
        ))}
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          justifyContent: 'space-evenly',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {step1Cards.map((card, i) => (
          <StepOneCards
            key={i}
            card={card}
            cardNo={i + 1}
            getStarted={handleNext}
          />
        ))}
      </Box>
      {step1Text
        .filter((_, index) => index >= step1Text.length - 1)
        .map((text) => (
          <Stack
            key={`step-${text.text}`}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="body1" color={text.color}>
              {String(translate(`pensionProvision.${text.text}`))}
            </Typography>
          </Stack>
        ))}

      {/* <Stack spacing={3}> */}
      {/*  {translatedQuestions.map((question, index) => ( */}
      {/*    <Controller */}
      {/*      key={question.value} */}
      {/*      name={question.value} */}
      {/*      control={control} */}
      {/*      defaultValue={handleDefaultValues(question.value)} */}
      {/*      render={({ field: { onChange, value }, fieldState: { error } }) => ( */}
      {/*        <StyledRadioGroup */}
      {/*          aria-label={question.id} */}
      {/*          name={question.value} */}
      {/*          value={value} */}
      {/*          onChange={(e) => { */}
      {/*            onChange(e); */}
      {/*            handleOnChange(question.value, e); */}
      {/*          }} */}
      {/*        > */}
      {/*          <Typography */}
      {/*            variant="subtitle1" */}
      {/*            sx={{ mb: 1, mt: index === 0 ? 2 : 0 }} */}
      {/*          > */}
      {/*            {String(question.question)} */}
      {/*          </Typography> */}
      {/*          <Stack */}
      {/*            direction={{ */}
      {/*              xs: 'column', */}
      {/*              md: 'row', */}
      {/*            }} */}
      {/*            flexDirection="row" */}
      {/*            spacing={2} */}
      {/*          > */}
      {/*            {question.answers.map((option) => ( */}
      {/*              <StyledFormControlLabel */}
      {/*                key={`step-${question.id}-${option.value}`} */}
      {/*                value={option.value} */}
      {/*                control={<StyledRadio />} */}
      {/*                label={String(option.label)} */}
      {/*              /> */}
      {/*            ))} */}
      {/*          </Stack> */}
      {/*          {!!error && ( */}
      {/*            <FormHelperText error={!!error} sx={{ mx: 0 }}> */}
      {/*              {error && error?.message} */}
      {/*            </FormHelperText> */}
      {/*          )} */}
      {/*        </StyledRadioGroup> */}
      {/*      )} */}
      {/*    /> */}
      {/*  ))} */}
      {/* </Stack> */}
    </Stack>
  );
};

export default ProvisionStep1;
